// src/components/TopBar/TopBar.tsx
import React, { useState, useEffect } from 'react';
import './TopBar.scss';
import logo from "../../assets/logo-white.png"
import mobileLogo from "../../assets/icon.png"; // Path to the new mobile logo
import { MailOutlined } from '@ant-design/icons'; // Import Ant Design email icon

const TopBar: React.FC = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth); // State for storing window width

    // Hook to handle window resize event
    useEffect(() => {
        // Function to update the window width state
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener for resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to ensure this runs only once when component mounts


    return (
        <div className="top-bar">
            <div className="top-bar-left">
                <img
                    src={window.innerWidth <= 768 ? mobileLogo : logo} // Conditionally render mobile or full logo
                    alt="Pacer Logo"
                    className="logo"
                />
            </div>
            
            <h1 className="top-bar-title">Live Auctions</h1>
            
            <div className="top-bar-right">
                {window.innerWidth <= 768 ? (
                    // Show email icon on mobile
                    <a href="mailto:info@pacer.co">
                        <MailOutlined style={{ color: '#ffffff', fontSize: '1.5rem' }} />
                    </a>
                ) : (
                    // Show full email on desktop
                    <a href="mailto:info@pacer.co">info@pacer.co</a>
                )}
            </div>
        </div>
    );
};

export default TopBar;
