import React, { useState, useEffect, useCallback } from 'react';
import { Select, Spin } from 'antd';
import { searchCars } from '../utils/searchCars';
import { SearchBar } from '../components/SearchBar/SearchBar';
import { FilterOptions } from '../components/FilterOptions/FilterOptions';
import { CarTable } from '../components/CarTable/CarTable';
import CarCards from '../components/CarCards/CarCards';
import TopBar from '../components/TopBar/TopBar';
import './Home.scss';

const { Option } = Select;

interface CarCardProps {
    key: string;
    name: string;
    currentBid: string;
    timeLeft: string;
    location: string;
    image: string;
    link: string;
}

const Home: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCars, setFilteredCars] = useState<CarCardProps[]>([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = useState(1); // Add page state
    const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Set initial state based on window width
    const [cars, setCars] = useState([]);
    const [filterValues, setFilterValues] = useState({});
    const [loading, setLoading] = useState(false);

    // Handler to update items per page
    const handleItemsPerPageChange = (value: number) => {
        setItemsPerPage(value);
    };

    // Handler to update search term
    const handleSearchTermChange = (term: string) => {
        setSearchTerm(term);
    };

    const handleFilterValuesChange = (newFilterValues: any) => {
        setFilterValues(newFilterValues);
    };


    // Helper function to format time left
    const formatTimeLeft = (endTime: number): string => {
        const timeDiff = endTime * 1000 - Date.now();
        if (timeDiff <= 0) return 'Ending Soon';

        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        return `${minutes} minutes, ${seconds} seconds`;
    };

    // Fetch data from the server when the page, itemsPerPage, searchTerm, or filter info changes
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const params = {
                page,
                itemsPerPage,
                searchTerm,
                ...filterValues, 
            };
            const data = await searchCars(params);
            setLoading(false);
            setCars(data);
        };
    
        fetchData();
    }, [page, itemsPerPage, searchTerm, filterValues]);
    
    // format car data to match table output
    useEffect(() => {
        if (cars) {
            const formattedData = cars
                .map((car: any, index: number) => ({
                    key: car.Link || index.toString(),
                    name: car.Name,
                    currentBid: `$${car.Price.toLocaleString()}`,
                    timeLeft: formatTimeLeft(car.EndTime),
                    location: car.Location,
                    image: car.imageLink,
                    link: car.Link,
                }));

            setFilteredCars(formattedData);
        }
    }, [cars]);

    // Effect to listen to window resize events and update `isMobile` state
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);    

return (
    <div className="home-container">
        <TopBar />
        <div className="main-content">
            {isFilterCollapsed ? (
                <button 
                    className="expand-button"
                    onClick={() => setIsFilterCollapsed(false)}
                >
                    Show Filters
                </button>
            ) : (
                <div className="filter-sidebar">
                    <SearchBar onSearchTermChange={handleSearchTermChange} />
                    <FilterOptions onFilterValuesChange={handleFilterValuesChange} />
                    <div className="items-per-page">
                        <span>Items per page:</span>
                        <Select defaultValue={10} onChange={handleItemsPerPageChange}>
                            <Option value={10}>10</Option>
                            <Option value={20}>20</Option>
                            <Option value={50}>50</Option>
                        </Select>
                    </div>
                    <button 
                        className="collapse-button"
                        onClick={() => setIsFilterCollapsed(true)}
                    >
                        Hide Filters
                    </button>
                </div>
            )}
            <div className="table-container">
                <Spin spinning={loading} size="large">
                {isMobile ? (
                    <CarCards cars={filteredCars} />
                ) : (
                    <CarTable itemsPerPage={itemsPerPage} dataSource={filteredCars} />
                )}
                </Spin>
            </div>
        </div>
    </div>
);
};


export default Home;
