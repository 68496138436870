// carCards.tsx
import React from 'react';
import './CarCards.scss';

interface CarCardProps {
    key: string;
    name: string;
    currentBid: string;
    timeLeft: string;
    location: string;
    image: string;
    link: string; // Add the link property
}

const CarCards: React.FC<{ cars: CarCardProps[] }> = ({ cars }) => {
    const handleViewClick = (link: string) => {
        window.open(link, '_blank'); // Opens link in a new tab
    };

    return (
        <div className="car-cards-container">
            {cars.map((car) => (
                <div className="car-card" key={car.key}>
                    <div className="image-container">
                        <img src={car.image} alt={car.name} />
                        <div className="time-left">{car.timeLeft}</div>
                    </div>
                    <div className="car-details">
                        <h3 className="car-name">{car.name}</h3>
                        <p className="car-price">{car.currentBid}</p>
                        <p className="car-location">Location: {car.location}</p>
                        <button
                            className="view-button"
                            onClick={() => handleViewClick(car.link)} // Pass link to handler
                        >
                            View
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CarCards;
