// utils/searchCars.ts
import axios from 'axios';

/**
 * Sends a POST request with the search term.
 * @param searchTerm The term to search for
 * @returns The response from the server
 */

interface SearchParams {
    page: number;
    itemsPerPage: number;
    [key: string]: any;
}

export const searchCars = async (params: SearchParams) => {
    const { searchTerm, page, itemsPerPage, ...otherParams } = params;

    const data = {
        query: searchTerm,
        page: page,
        items_per_page: itemsPerPage,
        ...otherParams
    }
    
    console.log(data)

    try {
        const response = await axios.post('/search', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data
    } catch (error) {
        console.error('Error posting data:', error);
    }
};
