import React, { useState } from 'react';
import axios from 'axios';
import Filters from './components/Filters';
import CarList from './components/CarList';
import Home from './views/Home';

function App() {
  const [cars, setCars] = useState([]);
  const [numberOfCars, setNumberOfCars] = useState(0);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const fetchCars = async () => {
    const response = await axios.get('/api/cars', {
      params: { page, items_per_page: itemsPerPage }
    });
    setCars(response.data.cars);
    setNumberOfCars(response.data.totalCars);
  };

  return (
    <div className="bg-gray-300 flex">
      <Home />
    </div>
  );
};

export default App;