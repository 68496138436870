import React from 'react';
import { Table, Button } from 'antd';
import './CarTable.scss';
import logo from "../../assets/logo-white.png"

interface CarTableProps {
    itemsPerPage: number;
    dataSource: Array<{
        key: string;
        name: string;
        currentBid: string;
        timeLeft: string;
        location: string;
        image: string;
        link: string; // Add link property here
    }>;
}

const CarTable: React.FC<CarTableProps> = ({ itemsPerPage, dataSource }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => (
                <div className="table-name-container">
                    <img 
                        src={record.image} 
                        alt={text} 
                        className="table-image" 
                        onError={(e) => { e.currentTarget.src = logo; }} // Optional fallback image
                    />
                    <span className="table-name">{text}</span>
                </div>
            ),
        },
        {
            title: 'Current Bid',
            dataIndex: 'currentBid',
            key: 'currentBid',
            render: (text: string) => <span className="table-bid">{text}</span>,
        },
        {
            title: 'Time Left',
            dataIndex: 'timeLeft',
            key: 'timeLeft',
            render: (text: string) => <span className="table-time">{text}</span>,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            render: (text: string) => <span className="table-location">{text}</span>,
        },
        {
            title: 'Link',
            key: 'link',
            render: (_: any, record: any) => (
                <Button 
                    type="primary" 
                    onClick={() => window.open(record.link, '_blank')}
                >
                    View
                </Button>
            ),
        },
    ];

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: itemsPerPage }}
            className="car-table"
        />
    );
};

export { CarTable };
