import React, {useState, useEffect} from 'react';
import { Collapse, Button } from 'antd';
import { FilterYear } from './filterYear/FilterYear';
import { FilterMakes } from './filterMakes/FilterMakes';
import { FilterLocation } from './filterLocation/FilterLocation';
import { FilterWebsite } from './filterWebsite/FilterWebsite';

import { useMakes } from '../../utils/getMakes';

import './FilterOptions.scss';

const { Panel } = Collapse;

const defaultFilterValues = {
    selectedMakes: [],
    websites: [],
    startYear: '',
    endYear: '',
    location: '',
    distance: '',
    
    // Add other default filter values here
};

interface FilterOptionsProps {
    onFilterValuesChange: (filterValues: any) => void;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({ onFilterValuesChange }) => {
    const {makes} = useMakes();
    const [filterValues, setFilterValues] = useState(defaultFilterValues);

    const handleFilterChange = (updates: { [key: string]: string | string[] }) => {
        setFilterValues(prevValues => ({ 
            ...prevValues, 
            ...updates 
        }));
    };

    useEffect(() => {
        onFilterValuesChange(filterValues);
    }, [filterValues, onFilterValuesChange]);
    
    
    const clearAllFilters = () => {
        setFilterValues(defaultFilterValues);
    };

    return (
        <div className="filter-options">
            <Collapse>
                <Panel header="Filter by Make" key="1">
                    <FilterMakes 
                        makes={makes}
                        selectedMakes={filterValues.selectedMakes}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}/>
                </Panel>
                <Panel header="Filter by Model (Beta)" key="2">
                    {/* Add model filter content here */}
                </Panel>
                <Panel header="Filter by Year" key="3">
                    <FilterYear 
                        startYear={filterValues.startYear} 
                        endYear={filterValues.endYear} 
                        onChange={(id, value) => handleFilterChange({ [id]: value })}/>
                </Panel>
                <Panel header="Filter by Location" key="4">
                    <FilterLocation
                        location={filterValues.location}
                        distance={filterValues.distance}
                        onChange={handleFilterChange}
                    />
                </Panel>
                <Panel header="Filter by Website" key="5">
                    <FilterWebsite 
                        websites={filterValues.websites}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}
                    />
                </Panel>
            </Collapse>
            <Button type="primary" className="clear-filters" onClick={clearAllFilters}>Clear All Filters</Button>
        </div>
    );
};

export { FilterOptions };