import { Input } from "antd";
import './FilterYear.scss';

interface FilterYearProps {
  startYear: string;
  endYear: string;
  onChange: (id: string, value: string) => void;
}

export const FilterYear: React.FC<FilterYearProps> = ({ startYear, endYear, onChange }) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(id, inputValue);
    }
  };

  return (
    <span className="filter-year-container">
      <div className="filter-year-input">
        <Input 
          value={startYear}
          onChange={handleChange}
          placeholder="Year"
          maxLength={4}
          id='startYear'
        />
      </div>
      <span>to</span>
      <div className="filter-year-input">
        <Input 
          value={endYear}
          onChange={handleChange}
          placeholder="Year"
          maxLength={4}
          id='endYear'
        />
      </div>
    </span>
  );
};