import { useState, useEffect } from 'react';

type Makes = string[]; // Define a more specific type for your car data if possible

export function useMakes() {
    const [makes, setMakes] = useState<Makes>([]);

    useEffect(() => {
        fetch("/get_makes")
            .then(res => res.json())
            .then(data => {
                setMakes(data);
            })
            .catch(error => console.error("Error fetching makes:", error));
    }, []);

    return { makes };
}

export default useMakes;
